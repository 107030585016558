<template>
	<div class="wrapper">
		<base-font variant="heading" marginReset>
		{{langs.changePassword.text}}
		</base-font>
		<form  class="form" v-if="form">
			<info-box v-if="!!currentPageView.status.code" :status="currentPageView.status.code">
				{{currentPageView.status.message}}
			</info-box>
			<div class="form-inner-wrapper">
				<input-template :input="form.inputs.oldPassword" autocomplete="email"  />
				<input-template :input="form.inputs.password" autocomplete="email" />
			</div>
			<button-submit @click="currentPageView.changePassword()" :loading="currentPageView.processing" type="secondary" :rightSlot="true">
				<base-font color="white" tag="span">{{langs.changePassword.button}}</base-font>
			</button-submit>
		</form>
	</div>
</template>
<script>
import ButtonSubmit from '~/website/front/components/molecules/ButtonSubmit'
import { InputTemplate } from '@f/core/forms/inputs'
import InfoBox from '~/authorization/front/components/InfoBox'

export default {
	components: {
		ButtonSubmit,
		InputTemplate,
		InfoBox
	},
	computed: {
		page () {
			return this.$app.page
		},
		currentPageView () {
			return this.page.view
		},
		form () {
			return this.currentPageView.form
		},
		langs () {
			return this.$app.translator.get('client-profile')
		}
	}
}
</script>
<style lang="scss" scoped>
.wrapper {
	.form {
		@include media-breakpoint-up(lg) {
			margin: 3rem 0;
		}
		& ::v-deep label {
			color: $primary;
		}
		& ::v-deep .field-wrapper {
			@include media-breakpoint-up(xl) {
				width: 350px;
			}
		}
		& ::v-deep .btn {
			width: 100%;
			margin-bottom: 0;
			@include media-breakpoint-up(lg) {
				margin-right: auto;
				max-width: 35%;
			}
			@include media-breakpoint-up(xl) {
				max-width: 25%;
			}
		}
	}
}
</style>
